/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f9fc; /* Light background */
  color: #002366;
}

.hero {
  text-align: center;
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #e0f7fa, #80deea); /* Cyan gradient */
  color: #004d40;
}

.hero h1 {
  font-size: 2.5rem;
  color: #002366;
}

.hero p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.cta-button {
  background-color: #00bfa5;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 191, 165, 0.3);
  transition: transform 0.2s ease;
}

.cta-button:hover {
  transform: scale(1.05);
}

.media-mentions {
  text-align: center;
  padding: 2rem;
}

.media-mentions h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.media-logos {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.media-logos img {
  max-width: 100px;
  margin: 0.5rem;
}

.program-overview {
  padding: 3rem 1rem;
  background-color: #ffffff;
  color: #004d40;
  text-align: center;
}

.program-overview h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.features {
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.feature {
  flex: 1;
  min-width: 250px;
  padding: 1rem;
  background-color: #e0f7fa;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.feature h3 {
  font-size: 1.3rem;
  color: #002366;
}

.feature p {
  font-size: 1rem;
  color: #004d40;
}

.founder-message {
  padding: 2rem 1rem;
  background-color: #e0f7fa;
  text-align: center;
}

.founder-message h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #002366;
}

.founder-message p {
  font-size: 1.2rem;
  color: #004d40;
  max-width: 600px;
  margin: 0 auto;
  font-style: italic;
}

.contacts {
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-info a {
  color: #00bfa5;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.footer {
  text-align: center;
  padding: 2rem;
  background-color: #004d40; /* Deep cyan */
  color: white;
  margin-top: 2rem;
}

.footer img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.footer-info {
  font-size: 1rem;
  color: #b2dfdb; /* Lighter cyan for contrast */
}

.disclaimer {
  padding: 1rem;
  background-color: #004d40;
  color: #ffffff;
  text-align: center;
}

.disclaimer p {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}
